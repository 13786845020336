<template>
  <el-form ref="form" :model="form" :rules="rules" label-width="80px" style="width:80%;height:450px;margin:50px auto;">
    <el-form-item label="详细地址"  prop="address_name">
      <el-input v-model="form.address_name"></el-input>
    </el-form-item>
    <el-form-item label="经度" prop="longitude">
      <el-input :disabled="true" v-model="form.longitude"></el-input>
    </el-form-item>
    <el-form-item label="纬度" prop="latitude">
      <el-input :disabled="true" v-model="form.latitude"></el-input>
    </el-form-item>
    <el-form-item label="地点">
<!--      <el-col :md="6">-->
<!--        <el-input :disabled="true" v-model="form.position"></el-input>-->
<!--      </el-col>-->
      <el-col :span="24" style="margin-top:15px;">
        <AMapPosition ref="map"  @choosePosition="choosePosition" :height="300"></AMapPosition>
      </el-col>
    </el-form-item>
    <el-form-item>
      <el-button type="primary" @click="$router.go(-1)"> 返回 </el-button>
      <el-button type="success" @click="saveData()">
        提交
      </el-button>
    </el-form-item>
<!--    <el-form-item label="设置中心">-->
<!--      <el-button type="success" @click="setCenter">设置中心点</el-button>-->
<!--    </el-form-item>-->
  </el-form>

</template>

<script>
import AMapPosition from "@/components/AMapPosition";
import { store,detail } from "@/api/address";

export default {
  components: {
    AMapPosition
  },
  data() {
    return {
      id:"",
      form: {
        id:"",
        address_name: "",
        longitude: "",
        latitude:""
      },
      rules: {
        address_name: [{ required: true, message: '请选择地址', trigger: 'change' }],
        longitude: [{ required: true, message: '请选择地址', trigger: 'change' }],
        latitude: [{ required: true, message: '请选择地址', trigger: 'change' }],
      }
    };
  },
  created() {
    console.log(this.$route.query);
    if (this.$route.query.id) {
      this.id = this.$route.query.id
      this.getDetail(this.id)
    }
  },
  methods: {
    choosePosition(res) {
      console.log(res);
      this.form.longitude =  res.position.lng;
      this.form.latitude = res.position.lat;
      this.form.address_name = res.address;
    },
    setCenter() {
      this.$nextTick(() => {
        this.$refs["map"].setCenter(116.410389, 39.903621);
      });
    },
    saveData() {
      this.$refs["form"].validate((valid) => {
        if(valid) {
          this.$confirm("此操作将添加地址, 是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
              .then(() => {
                store(this.form).then((response) => {
                  this.$router.go(-1); //返回上一层
                  this.$message({
                    type: "success",
                    message: "操作成功!",
                  });
                }).catch(() => {
                  this.$message({
                    type: "info",
                    message: "已取消",
                  });
                });
              })
          }
      })
    },
    getDetail(id) {
      detail(id).then((response) => {
        this.form = response.data

        this.$nextTick(() => {
          this.$refs["map"].setCenter(this.form.longitude, this.form.latitude);
        });
      })
    }
  }
};
</script>

<style>

</style>
