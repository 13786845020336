import request from '@/utils/request'

export const store = (data) => {
    return request({
        url: '/api/backend/address/store',
        method: 'post',
        data: data
    })
}


export const list = (listQuery) => {
    return request({
        url: '/api/backend/address/list',
        method: 'get',
        params: listQuery
    })
}

export const detail = (id) => {
    return request({
        url: '/api/backend/address/detail',
        method: 'get',
        params: {id: id}
    })
}

export const delAddress = (id) => {
    return request({
        url: '/api/backend/address/delete',
        method: 'delete',
        data: {id: id}
    })
}

export const stopOrStart = (id) => {
    return request({
        url: '/api/backend/address/stopOrStart',
        method: 'post',
        data: {id: id}
    })
}


export const importAddress = (data) => {
    return request({
        url: '/api/backend/address/import',
        method: 'post',
        data: {
            address: data,
        }
    })
}
